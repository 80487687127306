<template>
  <div>
    <PageHeader fileName="header-16" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph1") }}
            </h6>
            <p>
              {{ $lang("header.paragraph2") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-7">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-md-4">
            <ImageContainer
              class="img-container-rounded h-100 minh-5"
              fileName="extra-activities-1"
            />
          </div>
          <div class="col-md-8">
            <div class="row mb-4 gap-y-4">
              <div class="col-sm-6">
                <ImageContainer
                  class="img-container-rounded h-100 minh-5"
                  fileName="extra-activities-2"
                />
              </div>
              <div class="col-sm-6">
                <ImageContainer
                  class="img-container-rounded h-100 minh-5"
                  fileName="extra-activities-3"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ImageContainer
                  class="img-container-rounded h-100 minh-5"
                  fileName="extra-activities-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h4 v-html="$lang('section1.title')" />
          </div>
          <div class="col-lg-6 offset-lg-2">
            <p>{{ $lang("section1.subtitle") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-7">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-md-4">
            <div class="row gap-y-4">
              <div class="col-12">
                <ImageContainer
                  class="img-container-rounded h-100 minh-5"
                  fileName="extra-activities-5"
                />
              </div>
              <div class="col-12">
                <ImageContainer
                  class="img-container-rounded h-100 minh-5"
                  fileName="extra-activities-6"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <ImageContainer
              class="img-container-rounded h-100 minh-5"
              fileName="extra-activities-7"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row mb-7">
          <div class="col-12">
            <h4 class="text-secondary mb-5">{{ $lang("section2.title") }}</h4>
          </div>
          <div
            class="col-md-6 col-lg-4 pb-5"
            v-for="(review, ri) of $lang('section2.reviews')"
            :key="ri"
          >
            <div class="extra-activities-review">
              <p>{{ review.comment }}</p>
              <p class="fw-600">{{ review.person }}</p>
            </div>
          </div>
        </div>

        <div class="row gap-y-4 mb-4">
          <div class="col-lg-8">
            <ImageContainer
              class="img-container-rounded h-100 minh-5 mb-4"
              fileName="extra-activities-8"
            />
          </div>
          <div class="col-lg-4">
            <ImageContainer
              class="img-container-rounded h-100 minh-5 mb-4"
              fileName="extra-activities-9"
            />
          </div>
        </div>
        <div class="row gap-y-4">
          <div class="col-lg-4">
            <ImageContainer
              class="img-container-rounded h-100 minh-5 mb-4"
              fileName="extra-activities-10"
            />
          </div>
          <div class="col-lg-8">
            <ImageContainer
              class="img-container-rounded h-100 minh-5 mb-4"
              fileName="extra-activities-11"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-secondary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0">
                {{ $lang("footer.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ path: '/courses/our-courses' }"
            >
              <small class="m-0">
                {{ $lang("footer.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'student_visa' }"
            >
              <small class="m-0">
                {{ $lang("footer.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-15" theme="secondary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection> 
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import CardButton from "@/components/CardButton.vue";
export default {
  components: {
    CardButton,
    PageHeader,
    ContactFormSection,
    ImageContainer,
  },
};
</script>

<style></style>
